import axios from "axios";
import {config} from "../../config";
import {QHistory} from "../../QHistory";
import {RouteDefine} from "../../routes/RouteDefine";
import {QHelpers} from "../helpers";

// default
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let message;
        switch (error?.response?.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                localStorage.clear();
                return QHistory.push(RouteDefine.login)
            // case 403:
            //     QHistory.push(RouteDefine.forbidden);
            //     break;
            case 404:
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }

        if(error?.response?.status === 403){
            return error.request;
        }

        if(error?.response?.status === 400){
            return Promise.reject(error?.response);
        }

        else
            return Promise.reject(message);
    }
);

const setAuthorization = (token: string) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const APIClient = {
    get: (url: string, params: object|any, isAuth = true) => {
        let response;

        let paramKeys: Array<any> = [];

        let headers: any = {};
        let sToken = QHelpers.store.sessionStorageGetItem(config.LOCAL_STORE.ACCOUNT_SWITCH)

        if(sToken && isAuth){
            headers = {
                Authorization: "Bearer " + sToken
            }
        }

        if (params) {
            Object.keys(params).map(key => {
                paramKeys.push(key + '=' + params[key]);
                return paramKeys;
            });

            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
            response = axios.get(`${url}?${queryString}`, {...params, headers});
        } else {
            response = axios.get(`${url}`, {...params, ...headers});
        }

        return response;
    },

    create: (url: string, data : any, isAuth = true) => {

        let headers: any = {};
        let sToken = QHelpers.store.sessionStorageGetItem(config.LOCAL_STORE.ACCOUNT_SWITCH)

        if(sToken && isAuth){
            headers = {
                Authorization: "Bearer " + sToken
            }
        }

        return axios.post(url, data, { headers: headers } );
    },

    update: (url: string, data: any, isAuth = true) => {

        let headers: any = {};
        let sToken = QHelpers.store.sessionStorageGetItem(config.LOCAL_STORE.ACCOUNT_SWITCH)

        if(sToken && isAuth){
            headers = {
                Authorization: "Bearer " + sToken
            }
        }

        return axios.put(url, data, { headers: headers });
    },

    delete: (url: string, _config : any, isAut = true) => {

        let headers: any = {};
        let sToken = QHelpers.store.sessionStorageGetItem(config.LOCAL_STORE.ACCOUNT_SWITCH)

        if(sToken && isAut){
            headers = {
                Authorization: "Bearer " + sToken
            }
        }

        return axios.delete(url, { ...config, ...headers });
    },
}
const getLoggedinUser = () => {
    const user = localStorage.getItem(config.LOCAL_STORE.ACCOUNT);
    if (!user) {
        return null;
    } else {
        return JSON.parse(user);
    }
};


export { APIClient, setAuthorization, getLoggedinUser };
