import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import {Dashboard} from "../features/private/Dashboard";
import Order from "../features/private/Order";
import Customer from "../features/private/Customer";
import {Integrate} from "../features/private/Integrate";
import {Business} from "../features/private/Business";
import {Economic} from "../features/private/Economic";
import { Document } from "../features/private/Document";
import { MobilePay } from "../features/private/MobilePay";
// import Setup from "../features/public/Setup";

export const privateRoute: Array<RouteInterface> = [
    {path: RouteDefine.dashboard, Component: Dashboard},
    {path: RouteDefine.order, Component: Order},
    {path: RouteDefine.customer, Component: Customer},
    // {path: RouteDefine.setup, Component: Setup},

    {path: RouteDefine.integrate, Component: Integrate},
    {path: RouteDefine.business, Component: Business},
    {path: RouteDefine.economic, Component: Economic},
    {path: RouteDefine.mobilePay, Component: MobilePay},
    // {path: RouteDefine.document, Component: Document},
]
