import React, { useEffect, useState } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/rootReducer";
import { QHelpers } from "../../../common/helpers";
import QModal from "../../../common/components/QModal";
import UserApi from "../../../common/api/functions/User";

export function ForgotPassword() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    useEffect(() => {
        if (objectParams.verify == 1) {
            setOpen(true);
        }
    }, [objectParams.verify])


    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
        }),
        onSubmit: async (values) => {
            await setLoading(true);
            await UserApi.ForgotPassword(values).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    dispatch(setToast({
                        message: response.data.message,
                        type: config.TOAST_TYPE.SUCCESS,
                    }))
                    await localStorage.setItem(
                        config.LOCAL_STORE.ACCOUNT,
                        JSON.stringify(response.data.data),
                    )
                    await history.push(RouteDefine.order)
                }

            }, (response: ResponseInterface | any) => {

                if (typeof response.data?.message == "string") {
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                }
            });
            await setLoading(false);
        }
    })

    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />
                <form action="" method="">
                    <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-400"> Forgot Password</h2>
                    <QTextBox
                        label={'Email'}
                        placeholder={'Enter username'}
                        // className={'Enter username'}
                        value={formik.values.email}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('email', value)
                        }}
                        error={formik.touched?.email && formik.errors?.email}
                    />
                    <div className="mt-4">
                        <QButton
                            content={'Submit'}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <br/>
                        <br/>
                        <span>Back </span><a className="cl-orange-zp fw-bold" href={'#'} onClick={()=>{history.push(RouteDefine.login)}}>log in</a>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ForgotPassword
