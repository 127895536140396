import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import BodyEconomicComponent from "./components/BodyMobilePayComponent";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";
import { config } from "../../../config";
import { QHelpers } from "../../../common/helpers";
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styles from "../../public/Setup/index.module.css";
import QIconCheck from "../../../common/components/QIcon/QIconCheck";
import {useTranslation} from "react-i18next";
import BodyMobilePayComponent from "./components/BodyMobilePayComponent";


export function MobilePay() {
    const { t } = useTranslation();
    const [setup, setSetup] = useState<SetupInterface>({});
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [copied3, setCopied3] = useState(false);

    useEffect(() => {
        if(copied1){
            setTimeout(() => {
                setCopied1(false)
            }, 1000)
        }
    }, [copied1])

    useEffect(() => {
        if(copied2){
            setTimeout(() => {
                setCopied2(false)
            }, 1000)
        }
    }, [copied2])

    useEffect(() => {
        if(copied3){
            setTimeout(() => {
                setCopied3(false)
            }, 1000)
        }
    }, [copied3])

    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            load().then(async () => {
                await setLoading(false);

                let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)

                if (objectParams?.token) {
                    await setSetup({
                        ...setup,
                        economic_token: objectParams?.token,
                    })
                    await setCreateNewClick(true);
                }
            });
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={t("mobilepay.title")}
                bodyContent={(
                    <BodyMobilePayComponent
                        model={setup}
                        setOpen={setCreateNewClick}
                        setSetup={setSetup}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">MobilePay</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">URL</h2>

                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"><i
                                                className="las la-pen fs-24 cl-br-ams ml-2"
                                                onClick={() => {
                                                    setCreateNewClick(true);
                                                }}
                                            /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-1">https://www.mobilepay.dk/erhverv/betalingslink/betalingslink-svar?phone=<span style={{color:"red"}}>{setup.phone??'[Phone]'}</span>&amount=<span style={{color:"red"}}>[Amount]</span>&comment=<span style={{color:"red"}}>[Reference]</span>&lock=1</p>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12 mb-2"><a href={QHelpers.string.generateUrlEconomic()}>{t("economic.generateNewToke")}</a></div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(MobilePay);
