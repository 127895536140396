import React from "react";
import {FormFeedback, Input, Label} from "reactstrap";

interface Props {
    value: string|any,
    onChange: Function|any,
    name?: string|any,
    label?: string|any,
    error?: string|any,
    placeholder?: string|any,
    type?: string|any,
    disabled?: boolean,
    isVisible?: boolean,
    className?: string|any,
}

const QTextBox: React.FC<Props> = ({
    name = '',
    value = '',
    onChange = null,
    error = '',
    label = '',
    placeholder = '',
    type = 'text',
    disabled = false,
    isVisible = true,
    className = 'form-control-zp',
}) => {
    if (isVisible)
        return (
            <div className="mb-3">
                { label ? (
                    <Label>
                        { label }
                    </Label>
                ) : null }
                <Input
                    disabled={disabled}
                    type={type}
                    onChange={(event: Event|any) => {
                        if(onChange) onChange(event.target.value);
                    }}
                    value={value}
                    className={className}
                    placeholder={placeholder}
                    invalid={error.length > 0}
                />
                { error ? (
                    <FormFeedback>
                        { error }
                    </FormFeedback>
                ) : null }

            </div>
    )
    else
        return (<></>);
}

export default QTextBox
