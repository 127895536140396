import React from "react";
import IconOOP from "../../../../assets/images/ams/oops.png";
import QButton from "../../../../common/components/QButton";
import {useHistory} from "react-router-dom";
import {RouteDefine} from "../../../../routes/RouteDefine";
interface Props {
    formik: any
}

const StepError: React.FC<Props> = ({formik}) => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div className="mt-5 position-relative text-center">
                <h2 className="mb-4 mt-3 fs-32 cl-oops fw-400">Oops !</h2>
                <p className="cl-bdd">Sorry, The integration you are setting for not connect! 😭</p>
                <div className="mt-3 mb-3">
                    <img src={IconOOP} height="96" alt={"Icon oops"} />
                </div>
                <div className="mt-5">
                    <QButton
                        size={''}
                        width={100}
                        content={'Back'}
                        className={'bg-done bdr-10 fs-16'}
                        type={''}
                        onClick={() => {
                            history.push(RouteDefine.login);
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepError