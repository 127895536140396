import {TableHeaderInterface} from "../../../../interfaces/TableHeaderInterface";

const headers = (t : any) => {
    return [
        {
            label: "",
            className: "w-20p",
            checkbox: true,
        },
        {
            label: t("log.id"),
            sort: 'id',
            className: "w-20p"
        },
        {
            label: t("log.order_id"),
            className: "w-20p"
        },
        {
            label: t("log.message"),
            className: "w-20p"
        },
        
        {
            label: t("log.created_at"),
            sort: 'created_at',
            className: "w-20p"
        },
    ]
}

export const OrderConfig = {
    headers,
}
