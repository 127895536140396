import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import { SetupInterface } from "../../../../interfaces/private/SetupInterface";
import { useHistory } from "react-router-dom";
import { QHelpers } from "../../../../common/helpers";
import { RouteDefine } from "../../../../routes/RouteDefine";
import { useTranslation } from "react-i18next";
import QSelect from "../../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { log } from "console";

interface Props {
    model: SetupInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setSetup: Function,
}
interface Layout{
    layoutNumber: number,
    name: string
}
const BodyLayoutComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc, setSetup }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [layouts, setLayouts] = useState([]);

    useEffect(() => {
        if (loading) {
            loadLayout().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])
    const loadLayout = async () => {
        try {
            await SetupApi.getLayout().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    console.log(response?.data.data);

                    setLayouts(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    let getNameLayout = (layouts:any,number:number) =>{
        if (layouts.length === 0) {
            return null;
        }
        let arrayObject = layouts.filter((l:any)=> l.layoutNumber == number);
        let object:Layout = arrayObject[0];
        return object?object.name:'';
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...model }}
                onSubmit={(values: any) => {
                    SetupApi.update(values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {

                            let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)
                            if (objectParams?.token) {
                                history.push(RouteDefine.economic)
                            }

                            await setOpen(false);
                            await setLoadingFunc(true);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={'Layout'}
                                        value={
                                            formik.values.layout? { label: getNameLayout(layouts,formik.values.layout), value: formik.values.layout } : (
                                                QHelpers.object.mapSelectOption(
                                                    formik.values.layout,
                                                    layouts?.map((c: Layout) => ({ label: c.name, value: c.layoutNumber }))
                                                )
                                            )
                                        }
                                        options={
                                            layouts?.map((c: Layout) => ({ label: c.name, value: c.layoutNumber  }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('layout', option.value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                            content={t("common.button.submit")}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyLayoutComponent
