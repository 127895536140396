import React from "react";
import QTextBox from "../../../../common/components/QTextBox";

interface Props {
    formik: any
}

const Step3AccountShip: React.FC<Props> = ({formik}) => {

    return(
        <React.Fragment>
            <QTextBox
                label={'User'}
                value={formik.values.gls_username}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_username', event);
                }}
            />
            <QTextBox
                label={'Password'}
                type={'password'}
                value={formik.values.gls_password}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_password', event);
                }}
            />
             <QTextBox
                label={'Customer Id'}
                value={formik.values.gls_customer_id}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_customer_id', event);
                }}
            />
             <QTextBox
                label={'Contact Id'}
                value={formik.values.gls_contact_id}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_contact_id', event);
                }}
            />
        </React.Fragment>
    )
}

export default Step3AccountShip;
