import React, { useEffect, useState } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { useDispatch } from "react-redux";
import { setToast, setUserRole } from "../../../app/rootReducer";
import { QHelpers } from "../../../common/helpers";
import QModal from "../../../common/components/QModal";
import { GeneralConstant } from "../../../common/constant/GeneralConstant";
import UserApi from "../../../common/api/functions/User";
import webhookEconomicForm from "../../../assets/images/logo/logomobile2app.png";


interface LocationState {
    agreementNumber: string;
    user: User;
    // add other properties if needed
}
interface User {
    name: string;
    // add other properties if needed
}
export function Confirm() {
    const history = useHistory();
    const location = useLocation();
    let response = location.state as LocationState;

    const [loading, setLoading] = useState(true);
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    let {token} = objectParams;
    
    // if(objectParams?.token){
    //     let token = objectParams?.token;
    // }
    useEffect(() => {

        if (loading) {
            // loadRedirect().then(async () => {
            //     await setLoading(false);
            // });
        }
    }, [loading])

    // const loadRedirect = async () => {
    //     try {
    //         let model = {
    //             token: objectParams?.token
    //         }
    //         await UserApi.getInfoCompany(model).then(async (response: ResponseInterface | any) => {
    //             if (response.status === config.STATUS_CODE.OK) {
    //                 response = response.data.data;
    //                 let modelUser = {
    //                     email: response.email
    //                 }
    //                 await UserApi.checkExistEmail(modelUser).then(async (responseUser: ResponseInterface | any) => {
    //                     if (responseUser.status === config.STATUS_CODE.OK) {
    //                         if (responseUser.data.data) {
    //                             return history.push(RouteDefine.order + '?token=' + model.token, response);
    //                         }
    //                         else {
    //                             return history.push(RouteDefine.register + '?token=' + model.token, response);

    //                         }
    //                     }

    //                 })


    //             }
    //         })

    //     } catch (err) {
    //     }
    //     if (loading) {
    //         await setLoading(false);
    //     }
    // }
    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <div className="container-detail">
                        <div className="overflow-hidden p-5 bdr-10  m-auto">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div><a href="#"><img src={webhookEconomicForm} height={200} width={700} /></a></div>
                                        </div>
                                        <div>
                                            <div className="mt-5 position-relative text-center">
                                                <h2 className="mb-4 mt-3 fs-23 fw-bold">You’re almost there!</h2>
                                                <p className="cl-bd">We have created you with us with information from the e-conomic account: <br />
                                                    {response?.user.name} on agreement no. {response?.agreementNumber}</p>
                                                <div className="mt-1">
                                                    <a href="" className="btn undefined btn bg-orange-zp bdr-10" type="button" onClick={() => { history.push(RouteDefine.config+'?economic_token='+token, response) }} >
                                                        Complete creation
                                                    </a>
                                                </div>
                                                <p className="mt-3">Click here to see our subscription terms, which we also send to you in our welcome email.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
