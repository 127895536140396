import React, { useEffect, useState } from "react";
import SetupApi from "../../../../common/api/functions/Setup";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { SetupInterface } from "../../../../interfaces/private/SetupInterface";
import { QHelpers } from "../../../../common/helpers";
import { setAuthorization } from "../../../../common/api/base";
import QLoading from "../../../../common/components/QLoading";
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styles from "../index.module.css";
import QIconCheck from "../../../../common/components/QIcon/QIconCheck";

interface Props {
    formik: any
}

const Step2EconomicWebHook: React.FC<Props> = ({ formik }) => {
    const [setup, setSetup] = useState<SetupInterface | any>({});
    const [loading, setLoading] = useState(true);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [copied3, setCopied3] = useState(false);

    useEffect(() => {

        let account: any = QHelpers.object.getItemLocal(config.LOCAL_STORE.ACCOUNT, true);

        if (account?.access_token) {
            setAuthorization(account.access_token);
        }

        SetupApi.detail().then(async (response: ResponseInterface | any) => {
            if (response?.status === config.STATUS_CODE.OK) {
                await setSetup(
                    response?.data?.data || {}
                )

                await setLoading(false);
            }
        })

        return () => {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if(copied1){
            setTimeout(() => {
                setCopied1(false)
            }, 1000)
        }
    }, [copied1])

    useEffect(() => {
        if(copied2){
            setTimeout(() => {
                setCopied2(false)
            }, 1000)
        }
    }, [copied2])

    useEffect(() => {
        if(copied3){
            setTimeout(() => {
                setCopied3(false)
            }, 1000)
        }
    }, [copied3])

    return (
        <React.Fragment>
            <div>
                <QLoading loading={loading} />
                <div className="row">
                    <div className="col-md-12">
                        {/*<h2 className="fw-bold fs-24 mb-2">Web hook</h2>*/}
                        <span id="helpBlock" className="help-block">You must register web hooks e-conomic. please following copy/paste text below.</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <table className={"table align-middle"}>
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td> <div className="row d-flex">
                                        <div className="col-md-8">
                                            <p>{setup?.webhook?.name}</p>
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <CopyToClipboard text={setup?.webhook?.name}>
                                                <a href="#" onClick={() => setCopied1(true)}>
                                                    <i
                                                        className="las la-copy fs-24 cl-br-ams ml-2"
                                                    />
                                                </a>
                                            </CopyToClipboard>
                                            {
                                                copied1 && (
                                                    <div className={`${styles.popupCopied} shadow-lg`}>
                                                         <span className="text-success">
                                                            Copied <QIconCheck className={'text-success'} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div></td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{setup?.webhook?.type}</td>
                                </tr>
                                <tr>
                                    <td>Url</td>
                                    <td><div className="row d-flex">
                                        <div className="col-md-8">
                                            <p className="w-450">{setup?.webhook?.url}</p>
                                        </div>
                                        <div className="col-md-4 text-end ">
                                            <CopyToClipboard text={setup?.webhook?.url}>
                                                <a
                                                    onClick={() => setCopied2(true)}
                                                    href="#">
                                                    <i className="las la-copy fs-24 cl-br-ams ml-2"/>
                                                </a>
                                            </CopyToClipboard>
                                            {
                                                copied2 && (
                                                    <div className={`${styles.popupCopied} shadow-lg`}>
                                                         <span className="text-success">
                                                            Copied <QIconCheck className={'text-success'} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Data posted</td>
                                    <td><div className="row d-flex">
                                        <div className="col-md-8">
                                            <p className="w-450">{setup?.webhook?.data_posted}</p>
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <CopyToClipboard text={setup?.webhook?.data_posted}>
                                                <a
                                                    onClick={() => { setCopied3(true) }}
                                                    href="#">
                                                    <i className="las la-copy fs-24 cl-br-ams ml-2"/>
                                                </a>
                                            </CopyToClipboard>
                                            {
                                                copied3 && (
                                                    <div className={`${styles.popupCopied} shadow-lg`}>
                                                         <span className="text-success">
                                                            Copied <QIconCheck className={'text-success'} />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Step2EconomicWebHook;
