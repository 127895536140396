import React, {useState} from "react";
import {Link} from "react-router-dom";
import {RouteDefine} from "../../routes/RouteDefine";
import {useTranslation} from "react-i18next";

interface Props {

}

const LeftSideBar01: React.FC<Props> = () => {
    const [stateUrl, setStateUrl] = useState(window.location.pathname)
    const {t} = useTranslation();
    return (
        <div className="col-md-auto">
            <div className="siderbar-nav-left mt-3 w-195">
                <h3 className="fs-16 cl-ams fw-bold mb-4">{t("common.leftSidebarO1.settings")}</h3>
                <ul className="pl-0">
                    <li className={`${stateUrl === RouteDefine.business ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.business)
                        }}
                    >
                        <Link to={RouteDefine.business}>{t("common.leftSidebarO1.businessInfo")}</Link>
                    </li>
                    {/*<li className={`${stateUrl === RouteDefine.integrate ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.integrate)
                        }}
                    >
                        <Link to={RouteDefine.integrate}>Integration</Link>
                    </li>*/}
                    <li className={`${stateUrl === RouteDefine.economic ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.economic)
                        }}
                    >
                        <Link to={RouteDefine.economic}>{t("common.leftSidebarO1.eConomic")}</Link>
                    </li>

                    <li className={`${stateUrl === RouteDefine.mobilePay ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.mobilePay)
                        }}
                    >
                        <Link to={RouteDefine.mobilePay}>{t("common.leftSidebarO1.mobilePay")}</Link>
                    </li>
                    {/* <li className={`${stateUrl === RouteDefine.shippingInformation ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.shippingInformation)
                        }}
                    >
                        <Link to={RouteDefine.shippingInformation}>{t("common.leftSidebarO1.shippingInformation")}</Link>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default LeftSideBar01;
