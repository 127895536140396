import React from "react";
import styles from "./index.module.css";
import {Spinner} from "reactstrap";

interface Props {
    loading: boolean
}

const QLoading: React.FC<Props> = ({loading}) => {
    return (
        <React.Fragment>
            { loading ? (
                // <div className={styles.qLoadingWrapper}>
                //     <div className={`d-flex justify-content-center mx-2 ${styles.qLoading}`}>
                //
                //         <Spinner
                //             className={'center'}
                //         >
                //             Loading...
                //         </Spinner>
                //     </div>
                // </div>
                <div className="position-relative">
                    <div className="line"></div>
                </div>
            ) : null }
        </React.Fragment>
    );
}

export default QLoading;