import {APIClient} from "../base";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";
import {IntegrateInterface} from "../../../interfaces/private/IntegrateInterface";

const IntegrateApi = {
    list: async (pagination: PaginationInterface) => await APIClient.get('/integrate', pagination),
    detail: async (id: number) => await APIClient.get('/integrate/' + id, {}),
    create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (id: number, model: IntegrateInterface) => await APIClient.update('/integrate/' + id, model),
    delete: async (id: number|string|any) => await APIClient.delete('/integrate/' + id, {}),
    cmsType: async () => await APIClient.get('/integrate/cms-type', {}),
}

export default IntegrateApi;
