import { APIClient } from "../base";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";

const SetupApi = {
    detail: async () => await APIClient.get('/setup', {}),
    getLayout: async (token = null ) => await APIClient.get('/setup/get-layouts', {'token' : token}),
    getLayouts: async (token = null ) => await APIClient.get('/setups/get-layouts-no-author', {'token' : token}),
    getWebhookInformation: async (token = null,accountNumber = null ) => await APIClient.get('/webhook-mobile-bay', {'token' : token, 'accountNumber': accountNumber}),
    // create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (model: SetupInterface) => await APIClient.update('/setup', model),
    // economicDetail: async () => await APIClient.get('/economic', {}),
    // economicUpdate: async (model:EconomicInterface) => await APIClient.update('/economic', model),
}

export default SetupApi;
