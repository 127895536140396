import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

const UserApi = {
    register: async (model: any) => await APIClient.create('/register', model),
    ResetPassword: async (model: { password_confirm: string, password: string, email: string, sign: string, expire: string }) => await APIClient.create('/reset-password', model),
    ForgotPassword: async (model: { email: string }) => await APIClient.create('/forgot-password', model),
    checkExistEmail: async (model: { accountNumber: string }) => await APIClient.get('/is-exist-email', model),
    ChangePassword: async (model: { current_password: string, new_password: string, new_password_confirm: string }) => await APIClient.create('user/change-password', model),
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/user/list-user-by-admin', pagination),
    getInfoCompany: async (model:{token: string}) => await APIClient.get('/get-info-company', model),
    switchUser: async (id: number|any) => await APIClient.get(`/user/switch-user-by-id/${id}`, {}),
    registerByEconomic:async (model: any) => await APIClient.create('/register-by-economic', model),
    delete:async (id: number|any) => await APIClient.delete(`/user/${id}`,{}),
}

export default UserApi;
